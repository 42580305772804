import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const PocilySlider = () =>  {

  const data = useStaticQuery(graphql`
    query {
      gwLogoWhite: file(relativePath: { eq: "gw-logo-white-vertical.png" }) {
        childImageSharp {
          fluid(maxWidth: 140, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      slide1: file(relativePath: { eq: "policy-slides/slide1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 20) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      slide2: file(relativePath: { eq: "policy-slides/slide2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 20) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      slide3: file(relativePath: { eq: "policy-slides/slide3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 20) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    arrows:false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true
  }
 
  return (
    <Slider {...settings} className="policy-slider">
      <div className="slide">
        <Img fluid={data.slide2.childImageSharp.fluid}/>
        <div className="inner">
          <p>EDI・IoT・クラウドで<br className="d-block d-lg-none" />お客様のDXを推進</p>
          <Img fluid={data.gwLogoWhite.childImageSharp.fluid}/>
        </div>
      </div>

      <div className="slide">
        <Img fluid={data.slide1.childImageSharp.fluid}/>
        <div className="inner">
          <p>広い視野と高い技術で<br className="d-block d-lg-none" />オンリーワンを創り出す</p>
          <Img fluid={data.gwLogoWhite.childImageSharp.fluid}/>
        </div>
      </div>

      <div className="slide">
        <Img fluid={data.slide3.childImageSharp.fluid}/>
        <div className="inner">
          <p>お客様と共に<br className="d-block d-lg-none" />ビジネスの成功を創造します</p>
          <Img fluid={data.gwLogoWhite.childImageSharp.fluid}/>
          </div>
      </div>  
    </Slider>
  )
}

export default PocilySlider