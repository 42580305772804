import React, { forwardRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Button } from 'react-bootstrap'
import { Timeline, Tween, Reveal } from 'react-gsap'

const McmPR = () =>  {

  const data = useStaticQuery(graphql`
    query {
      McmLogo: file(relativePath: { eq: "mcm-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      McmBack: file(relativePath: { eq: "illust/mcm-back.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      McmDevice: file(relativePath: { eq: "mcm-device.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const Contents = forwardRef((props, ref) => (
    <div>
      <div ref={div => ref.set('div1', div)} >
        <h1>
          モノ/コトを<span className="p-blue">ネットワークでつなぎ</span>、<br className="d-none d-lg-block" />
          データ収集/分析(アナリティクス)や<br className="d-none d-lg-block" />
          ⾃動化などによって<span className="p-blue">新たな価値</span>を創出。<br className="d-none d-lg-block" />
        </h1>
      </div>
      <div ref={div => ref.set('div2', div)}>
        <p>
          IoTにより今まで得られなかった多くのデータが得られるようになり、<br className="d-none d-lg-block" />
          またその多くのデータを効率よく処理するためにクラウドを活⽤。<br className="d-none d-lg-block" />
          遠隔地であっても、機械の稼働状況などをWEB上でいつでも把握でき、<br className="d-none d-lg-block" />
          ⾃動化によって、ヒューマンエラーの回避や削減、作業効率の向上を⽬指します。<br className="d-none d-lg-block" />
        </p>
      </div>
      <div ref={div => ref.set('div3', div)} className="text-center text-lg-left mt-5 mb-5" >
        <Button
          variant="primary" 
          href="https://www.g-wise.co.jp/mcm-special" 
          target="_blank" rel="noopener noreferrer"
        >
          GW-MCM System 特設ページはこちら
        </Button>
      </div>
    </div>
  ))

  const Wrapper = forwardRef((props, ref) => (
    <div className="product-PR">
      <Img fluid={data.McmLogo.childImageSharp.fluid} alt="GW-MCM System" className="mb-4 mx-auto ml-lg-0"/>
      <div className="bg-img">
        <Img fluid={data.McmBack.childImageSharp.fluid} alt="GW-MCM System" className="ml-auto"/>
      </div>
      {props.children}
      <div className="text-center text-lg-left">
        <Img fixed={data.McmDevice.childImageSharp.fixed} alt="GW-MCM System 装置"/>
      </div>
    </div>
  ))

  return (
    <Container lg="auto">
      <Reveal trigger={<Wrapper />}>
        <Timeline target={<Contents />}>
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} target="div1"/>
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} target="div2"/>
          <Tween from={{ opacity: 0 }} to={{ opacity: 1 }} target="div3"/>
        </Timeline>
      </Reveal>
    </Container>
  )
}

export default McmPR