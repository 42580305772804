import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick"

const ContentsSlider = () =>  {

  const data = useStaticQuery(graphql`
    query {
      TITCLogo: file(relativePath: { eq: "TITC-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      ediMovie: file(relativePath: { eq: "edi-movie.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      jnLogo: file(relativePath: { eq: "jn-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      gwLogo: file(relativePath: { eq: "gw-logo-orange-vertical.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      business: file(relativePath: { eq: "illust/business.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      topics: file(relativePath: { eq: "illust/topics.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      recruit: file(relativePath: { eq: "illust/recruit.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 50) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const Arrows = (props) => {
    let className = props.type === "next" ? "nextArrow" : "prevArrow"
    className += " arrow"
    const arrow = props.type === "next" ? <FontAwesomeIcon icon={faAngleRight} /> : <FontAwesomeIcon icon={faAngleLeft} />
    return (
      <div className={className} onClick={props.onClick} onKeyDown={props.onClick} role="button" tabIndex={0}>
        {arrow}
      </div>
    )
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 6,
    speed: 1000,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <Arrows type="next" />,
    prevArrow: <Arrows type="prev" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ]
  }

  return (
    <div className="contents-slider">
      <div className="h2-subtitle d-block d-lg-none">
        <h2>その他のコンテンツ</h2>
        <p className="small text-muted">Other Contents</p>
      </div>
      <Slider {...settings} >
        <div className="slide">
          <a href="https://tsunagu-it.com/cons/" target="_blank" rel="noopener noreferrer">
            <div className="icon align-items-center text-center">
              <Img fluid={data.TITCLogo.childImageSharp.fluid} alt="つなぐITコンソーシアム" />
            </div>
            <p>つなぐITコンソーシアム</p>
          </a>
        </div>

        <div className="slide">
          <a href="https://player.vimeo.com/video/275552856?title=0&byline=0&portrait=0" target="_blank" rel="noopener noreferrer">
            <div className="icon">
              <Img fluid={data.ediMovie.childImageSharp.fluid} alt="中⼩共通EDIとは"/>
            </div>
            <p>中⼩共通EDIとは</p>
          </a>
        </div>

        <div className="slide">
          <a href="https://jn2.japan-net.ne.jp" target="_blank" rel="noopener noreferrer">
            <div className="icon">
              <Img fluid={data.jnLogo.childImageSharp.fluid} alt="ジャパンネット" />
            </div>
            <p>ジャパンネット</p>
          </a>
        </div>

        <div className="slide">
          <Link to="/aboutus/">
            <div className="icon">
              <Img fluid={data.gwLogo.childImageSharp.fluid} alt="会社案内"/>
            </div>
            <p>会社案内</p>
          </Link>
        </div>

        <div className="slide">
          <Link to="/business/">
            <div className="icon">
              <Img fluid={data.business.childImageSharp.fluid} alt="SIerとしての23年の実績"/>
            </div>
            <p>SIerとしての23年の実績</p>
          </Link>
        </div>

        <div className="slide">
          <Link to="/topics/">
            <div className="icon">
              <Img fluid={data.topics.childImageSharp.fluid} alt="トピックス"/>
            </div>
            <p>トピックス</p>
          </Link>
        </div>

        <div className="slide">
          <Link to="/recruit/">
            <div className="icon">
              <Img fluid={data.recruit.childImageSharp.fluid} alt="採用情報"/>
            </div>
            <p>採用情報</p>
          </Link>
        </div>
      </Slider>

    </div>
  )
}

export default ContentsSlider